.lazy-load-image-background.blur.lazy-load-image-loaded {
  z-index: 99;
  position: relative;
}

.payment-card-media:before {
  content: "";
  position: absolute;
  width: 284px;
  height: 284px;
  background: #000;
  /* top: -10px; */
  border-radius: 100%;
  left: 50%;
  background: #188bf6;
  filter: blur(171.265px);
  transform: translate(-50%, -50%);
  top: 50%;
}
