.nav-slider .slick-slide {
  opacity: 0.4;
}

.nav-slider .slick-active {
  opacity: 1;
}

.nav-slider .slick-prev:before,
.nav-slider .slick-next:before {
  display: none;
}

.prev-button,
.next-button {
  background-color: white;
  border: 1px solid orange;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease, color 0.3s ease;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: orange;
  font-size: 1.5rem;
}

.prev-button:hover,
.prev-button:focus,
.next-button:hover,
.next-button:focus {
  background-color: orange;
  color: white;
}

.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.slick-dots.slick-thumb li {
  width: 15px;
  height: 15px;
  margin: 0 6px;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
}

.slick-dots li button:before {
  color: transparent;
}

.slick-dots.slick-thumb li.slick-active {
  background-color: #f68712;
}

.slick-dots li.slick-active button:before {
  color: transparent;
}
